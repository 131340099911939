<template>
  <div class="studentAccount">
    <el-form :inline="true" class="form-inline" @submit.native.prevent>
      <div class="create-account-btn" @click="handleClose">创建学员账号</div>
      <el-form-item>
        <el-select v-model="post.status">
          <el-option v-for="item in courseCateGory" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model.trim="post.mobile" placeholder="输入手机号搜索"></el-input>
      </el-form-item>
    </el-form>
    <!--列表-->
    <div class="table-contain">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333'
        }"
      >
        <el-table-column prop="mobile" label="登录手机"> </el-table-column>
        <el-table-column prop="pwd" label="密码"> </el-table-column>
        <el-table-column prop="uname" label="绑定学员">
          <template slot-scope="scope">
            <div class="user-info">
              <template v-if="scope.row.status == 2">
                <span class="name">--</span>
              </template>
              <template>
                <span class="photo">
                  <img :src="scope.row.uphoto" />
                </span>
                <span class="name">{{ scope.row.uname }}</span>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            {{ scope.row.status == 2 ? '未绑定' : '已绑定' }}
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template slot-scope="scope">
            {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <div class="btns">
              <span v-if="scope.row.status == 2" class="text copyBtn" @click="edit(scope.row)">编辑</span>
              <span v-else class="text" @click="unbind(scope.row)">解绑学员</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页-->
    <pagination :currPage="post.page" :total="total" @currentChange="pageCodeChange"></pagination>
    <!--创建学员弹窗-->
    <el-dialog
      title="创建学员账号"
      :visible.sync="accountVisible"
      width="800px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      @closed="accountConfirmClose"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="form"
        label-width="82px"
        class="inner-slot"
        label-position="right"
        @submit.native.prevent
        :hide-required-asterisk="true"
      >
        <el-form-item prop="mobile" label="登录手机号" class="add-account-item">
          <el-input v-model="formData.mobile" placeholder="请输入11位数手机号" maxlength="11" show-word-limit></el-input
        ></el-form-item>
        <el-form-item prop="password" label="登录密码" class="add-account-item">
          <el-input v-model="formData.password" placeholder="请输入6-16位数登录密码，仅支持英文/数字" maxlength="16" show-word-limit></el-input
        ></el-form-item>
      </el-form>
      <div slot="footer" style="text-align:right;">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="createAccount">{{ formData.account_id ? '保存' : '创建' }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import bg from '@/mixin/background'

export default {
  name: 'studentAccount',

  mixins: [bg],

  data() {
    return {
      tableData: [],
      post: {
        page: 1,
        status: 0,
        mobile: ''
      },
      total: 0,
      accountVisible: false,
      courseCateGory: [
        {
          name: '全部状态',
          id: 0
        },
        {
          name: '已绑定',
          id: 1
        },
        {
          name: '未绑定',
          id: 2
        }
      ],

      // 表单结果
      formData: {
        mobile: '',
        password: ''
      },

      // 表单验证规则
      rules: {
        mobile: [
          { required: true, message: '请输入登录手机号' },
          {
            pattern: /^1[2-9]\d{9}$/,
            message: '手机号是验证账户的凭证，请保证真实有效'
          },
          { validator: this.verifyMobile, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码' },
          {
            validator(rule, value, callback) {
              if (/[^\w]/.test(value)) {
                callback(new Error('仅支持英文/数字'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          },
          {
            type: 'string',
            range: true,
            min: 6,
            max: 16,
            message: '请输入6-16位数登录密码'
          }
        ]
      }
    }
  },

  watch: {
    post: {
      handler(newValue, oldValue) {
        const options = _.assign({}, this.post)
        // 如果page相等说明 变动的是其他数据 page 需重置
        if (newValue && oldValue && oldValue.page === newValue.page) {
          options.page = 1
        }
        this.getData(options)
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    getData(data) {
      this.$http({
        url: '/user/studentAccountList',
        data,
        isMultipleSelf: 'replacePrev',
        callback: ({ data, allNum }) => {
          this.total = Number(allNum)
          this.tableData = data
        },
        error: () => {
          this.total = 0
          this.tableData = []
        }
      })
    },

    // 页码变化
    pageCodeChange(value) {
      this.post = _.assign({}, this.post, { page: value })
    },

    // 创建学生账号弹出
    handleClose() {
      this.accountVisible = !this.accountVisible
    },

    // 学生账号弹窗关闭
    accountConfirmClose() {
      this.$refs.form && this.$refs.form.resetFields()
      this.formData = {
        mobile: '',
        password: ''
      }
      this.getData(this.post)
    },

    // 创建学生账号
    createAccount() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$http({
            url: this.formData.account_id ? '/user/studentAccountEdit' : '/user/createStudentAccount',
            mute: true,
            data: {
              mobile: this.formData.mobile,
              pwd: this.formData.password,
              account_id: this.formData.account_id || ''
            },
            callback: () => {
              this.accountVisible = false
            },
            error() {}
          })
        }
      })
    },

    // 解绑学员
    unbind({ account_id }) {
      this.$confirm('解绑后，该账号将被释放，原学员就无法使用，可交付给新学员；解绑后可进行编辑账号', '解绑学员', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$http({
          url: '/user/studentAccountUntying',
          data: {
            account_id
          },
          callback: () => {
            this.accountConfirmClose()
          }
        })
      })
    },

    // 验证手机号码
    verifyMobile(rule, value, callback) {
      if (value === this.formData.oldMobile) {
        callback()
      } else {
        this.$http({
          url: '/user/verifyAccount',
          mute: true,
          data: {
            mobile: value
          },
          callback: () => {
            callback()
          },
          error({ info }) {
            callback(new Error(info))
          }
        })
      }
    },

    // 编辑
    edit({ mobile, account_id, pwd }) {
      this.formData = {
        mobile,
        password: pwd,
        account_id,
        oldMobile: mobile
      }
      this.accountVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep.studentAccount {
  margin: 20px;
  background: #fff;

  .el-dialog__footer {
    padding-top: 135px;
  }
}
::v-deep .form-inline {
  text-align: right;
  position: relative;
  padding: 20px 10px 0 0;
  .el-select {
    width: 120px;
  }
  .create-account-btn {
    text-align: center;
    width: 100px;
    line-height: 36px;
    background: #0aa29b;
    border-radius: 4px;
    font-size: 14px;
    color: white;
    position: absolute;
    left: 20px;
    top: 22px;
    cursor: pointer;
  }
}
.table-contain {
  padding: 0 20px;
}
.btns {
  @extend %btns;
}
::v-deep .add-account-item {
  width: 625px;
  margin-bottom: 30px;
  .el-form-item__content {
    flex: 1;
  }
  .el-form-item__label {
    text-align: left;
  }
}
.user-info {
  display: flex;
  align-items: center;
  .photo {
    width: 27px;
    height: 27px;
    margin-right: 10px;
    border-radius: 14px;
    img {
      display: block;
      width: 100%;
    }
  }
  .name {
    font-size: 14px;
    line-height: 19px;
    color: rgba(51, 51, 51, 1);
  }
}
</style>
